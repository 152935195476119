import "../styles/main.scss";

console.log('%c Streamline', 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38)');

import "./use-cases/async-imports";
import "./use-cases/interacts";
import "./use-cases/onscroll";
// import "./use-cases/modal";
// import "./use-cases/lazyload";
// import "./use-cases/forms";
import "./use-cases/accordion";
// import "./use-cases/cookies";
// import "./use-cases/svg";
// import "./use-cases/select";
// import "./use-cases/image-map";
import "./use-cases/lightbox";
// import "./use-cases/hover-effects";
// import "./use-cases/count-up";
// import "./use-cases/anime";
// import "./use-cases/charts";
// import "./use-cases/menu"
import "./use-cases/cf7"
import './use-cases/smooth-scroll'
// import "./use-cases/orphantKiller";
import ReplaceText from "./modules/replaceText";
new ReplaceText();

