import Accordion from "../modules/accordion";

const elements = document.querySelectorAll('.js-accordion-wrapper')
if (elements && elements.length > 0) {
	elements.forEach(element => {
		const accordion = new Accordion(element);
	});
}

const mobileMenu = document.querySelector('.js-mobile-menu')
if(mobileMenu){
	new Accordion(mobileMenu, {
		items: '.js-menu-item-arrow'
	})
}