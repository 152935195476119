import fetchWrapper from "../modules/fetchWrapper";

if(document.querySelector('[data-cf7]')){
	(async () => {
		const cf7initiator = document.querySelector('[data-cf7]');
		const [status, result] = await fetchWrapper({
			url: `/wp-admin/admin-ajax.php?action=get_cf7_form&form=${cf7initiator.getAttribute('data-form')}`,
			method: "GET",
		});
		document.querySelector('[data-cf7]').innerHTML = result.data

		/** in case we disable cf7 scripts by wp_dequeue */
		// var recaptchaScript = document.createElement('script');
		// recaptchaScript.src = '/wp-content/plugins/contact-form-7/includes/js/index.js?ver=5.5.3';
		// document.body.appendChild(recaptchaScript);

		setTimeout(() => {
			wpcf7.init(document.querySelector('form'))
		}, 3000)
	})();
}
