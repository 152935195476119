let ScrollAction = function(args) {
  const that = this;
  this.wrapper = args.wrapper;
  this.offset = args.offset ? args.offset : 0;
	this.classToAdd = args.classToAdd;
	this.preClassToAdd = args.preClassToAdd;
	this.postClassToAdd = args.postClassToAdd;
	this.prePostClassesTimeout = args.prePostClassesTimeout || 400;
	this.isAlreadySticky = false;

  this.init = function() {
    this.cacheDom();
    bindEvents();
  };
  this.cacheDom = function() {
    this.el = document.querySelector(this.wrapper);
  };
  function bindEvents() {
    window.addEventListener("scroll", decideAction);
    window.addEventListener("load", decideAction);
  }

  function decideAction() {
    if (typeof that.offset === "number") {
      makeAction(that.offset);
    } else if (typeof that.offset === "string") {
      makeAction(document.querySelector(that.offset).offsetTop);
    }
  }

  function makeAction(argument) {
		let scrollTop = window.pageYOffset;

    if (scrollTop > argument) {
			if(!that.isAlreadySticky){
				if(that.preClassToAdd){
					that.el.classList.add(that.preClassToAdd);
					setTimeout(() => {
						that.el.classList.remove(that.preClassToAdd);
						that.el.classList.add(that.classToAdd);
					}, that.prePostClassesTimeout)
				}else{
					that.el.classList.add(that.classToAdd);
				}
			}
			that.isAlreadySticky = true
    } else {
			if(that.isAlreadySticky){
				that.el.classList.remove(that.classToAdd);
				if(that.postClassToAdd){
					that.el.classList.add(that.postClassToAdd);
					setTimeout(() => {
						that.el.classList.remove(that.postClassToAdd);
					}, that.prePostClassesTimeout)
				}
			}
			that.isAlreadySticky = false
    }
  }
};
module.exports = ScrollAction;

