import { reveal, preReveal } from '../modules/scroll-reveal';
import ScrollAction from "../modules/scroll-action";
import { jarallax } from "jarallax";
jarallax(document.querySelectorAll('.jarallax'));

// import Sticky from "sticky-js";
// new Sticky(".js-sticky");


preReveal('.js-reveal');

/** Hide */
// window.fade = function(el){
	reveal('.js-reveal', { reset: true });
// }

if(document.querySelector('.js-site-header')){
	new ScrollAction({
		wrapper: ".js-site-header",
		offset: 0,
		classToAdd: "is-sticky",
		postClassToAdd: "is-post-sticky",
		preClassToAdd: "is-pre-sticky",
		prePostClassesTimeout: 1,
	}).init();
}