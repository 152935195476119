export default class ReplaceText {
  constructor() {
    this.text = document.querySelectorAll('p');
    this.count_text = this.text.length;
    if (this.text != null) {
      this.bindEvents();
    }
  }
  bindEvents() {
    this.text.forEach(el => {
      this.replace_text(el);
    });
  }
  replace_text(element){
    var textReplace = element.textContent;

    var lettersToReplace = ["a","i","o","u","w","z","A","I","O","U","W","Z"];
    var arrayLength = lettersToReplace.length;
    for (var i = 0; i < arrayLength; i++) {
      var textSplit = textReplace.split(' ' + lettersToReplace[i] + ' ');
      var textReplace = textSplit.join(' ' + lettersToReplace[i] + '&nbsp;');
    }

    element.innerHTML = textReplace;
  }
}